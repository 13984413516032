.DropdownMenu__contentWrapper {
  position: relative;
}

.DropdownMenu__content {
  position: absolute;
  background: white;
  transition: opacity 250ms, top 250ms, visibility 250ms;
  padding: 5px 0;
  right: 0;
  min-width: 250px;
  border: 1px solid #cfcfcf;
  border-radius: 4px;
  /* box-shadow: 0 2px 3px rgba(0,0,0,0.3); */
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.09);
  z-index: 3;
}
.DropdownMenu__content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.DropdownMenu__content ul li > a,
.DropdownMenu__content ul li > span {
  display: block;
  padding: 0 10px 0 30px;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  color: #333b47;
  cursor: pointer;
}
.DropdownMenu__content ul li > a:hover,
.DropdownMenu__content ul li > span:hover {
  background: #349aef;
  color: white;
}

.DropdownMenu__contentClosed .DropdownMenu__content {
  visibility: hidden;
  opacity: 0;
  top: -20px;
}

.DropdownMenu__contentOpen .DropdownMenu__content {
  visibility: visible;
  opacity: 1;
  top: 0;
}
