.NavMenu {
  width: 100%;
  max-width: 212px;
  margin: 0 auto;
}
.NavMenu h6 {
  margin: 40px 0 24px;
  text-align: left;
  font-weight: 600;
  font-size: 14px;
}
.NavMenu a {
  display: block;
  margin: 2px 0;
}
.NavMenu li {
  font-size: 14px;
  line-height: 33px;
  margin: 0 auto;
  text-align: left;
  border-radius: 4px;
  background-color: transparent;
  transition: background-color 300ms;
}
.NavMenu a.is-active li {
  background-color: #d2e8fa;
}
.NavMenu li:hover {
  background-color: #d2e8fa;
}

.NavMenu li > span {
  display: inline-block;
  margin: 0 12px 0 16px;
  width: 16px;
  text-align: center;
}
