/* TODO: These are duplicates of SignInForm styles */
.ResetPasswordForm_container {
  max-width: 340px;
  width: 80%;
  margin: 0 auto;
}

.ResetPasswordForm__links-wrapper {
  margin: 1rem 0;
  display: flex;
  justify-content: space-around;
}

.ResetPasswordForm__title {
  margin: 1rem;
}
