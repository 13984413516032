.EditCaseProgramModal__section > h3 {
  font-size: 1.25rem;
  font-weight: 600;
}

.EditCaseProgramModal__section > article {
  margin: 1rem 1.5rem;
}

.CreateModalityForm {
  padding: 0.5rem;
  background-color: #eee;
  border-radius: 0.5rem;
  margin: 0.5rem 0;
}
