html {
  background-color: #ecf0f4;
}

body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.capitalize {
  text-transform: capitalize;
}

.Modal {
  /* position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px; */
  /* background-color: papayawhip; */
}

.Modal:focus {
  outline: none;
}

.Overlay {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgba(0, 0, 0, 0.5); */
  background-color: transparent;
  transition: background-color 150ms;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  overflow-y: scroll;
}

.ReactModal__Overlay--after-open.Overlay {
  background-color: rgba(0, 0, 0, 0.5);
}
.ReactModal__Overlay--before-close.Overlay {
  background-color: transparent;
}

.ReactModal__Content {
  opacity: 0;
  transform: scale(0.8) translateY(100px);
  margin: 4rem 0;
}

.ReactModal__Content--after-open {
  opacity: 1;
  transform: scale(1) translateY(0);
  transition: opacity 150ms, transform 150ms;
}

.ReactModal__Content--before-close {
  opacity: 0;
  transform: scale(0.7) translateY(100px);
}

.StepHeaderLogo {
  width: 90%;
  max-width: 160px;
  margin: 0 auto;
  text-align: center;
}
.StepHeaderLogo > img {
  max-width: 100%;
  max-height: 110px;
  margin: 0 auto;
}

.cols {
  display: -ms-flexbox;
  display: flex;
}

.col {
  display: block;
  -ms-flex: 1 1 0;
  flex: 1 1 0;
  /* flex-grow: 1; */
  /* flex-shrink: 1; */
  /* flex-basis: 0; */
}

.col.col-small {
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  /* flex-grow: 0; */
}

.field.is-horizontal .field-label {
  padding-top: 0.375em;
  padding-bottom: 0.375em;
}
.field.is-horizontal .field-body .field {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.column.is-vcentered,
.col.is-vcentered {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

span.icon-container {
  margin-right: 0.5em;
}

button.a {
  background: none;
  border: none;
  color: #3273dc;
  text-decoration: none;
  cursor: pointer;
  font-size: 1em;
}

button.dropdown-item {
  color: #4a4a4a;
  font-size: 0.875rem;
}

/* Branding Styling stuff */

.button.is-primary[disabled],
.button.is-primary {
  background-color: #c99700;
}
.button.is-primary:hover,
.button.is-primary.is-hovered {
  background-color: #b58b0b;
}
.button.is-primary:focus:not(:active),
.button.is-primary.is-focused:not(:active) {
  box-shadow: 0 0 0 0.125em rgba(209, 164, 0, 0.25);
}
.button.is-primary:active,
.button.is-primary.is-active {
  background-color: #b38b12;
}

/* These style the DateRangeDropdown selected days */
.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  /* background-color: #c99700 !important; */
  background-color: var(--accent-green-500) !important;
}

.accent-green-border-btm {
  position: relative;
  padding-bottom: 25px;
  margin-bottom: 25px;
}
.accent-green-border-btm:after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 4px;
  background: #008F83;
  width: 80px;
}
