@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .link {
    @apply hover:text-accent-green-600 font-semibold text-accent-green-500;
  }
}

/*
 * Bulma Resets
 */
/*
.box:not(:last-child),
.content:not(:last-child),
.notification:not(:last-child),
.progress:not(:last-child),
.table:not(:last-child),
.table-container:not(:last-child),
.title:not(:last-child),
.subtitle:not(:last-child),
.block:not(:last-child),
.highlight:not(:last-child),
.breadcrumb:not(:last-child),
.level:not(:last-child),
.list:not(:last-child),
.message:not(:last-child),
.tabs:not(:last-child) {
  margin-bottom: unset;
} */
