.ResultsPage__ProviderResult {
  display: block;
  max-width: 1100px;
  margin: 0 auto 1.25rem;
  background-color: #ffffff;
  border: 1px solid var(--gray-200);
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.075);
}

.ResultsPage__ProviderResult__header {
  padding: 1rem 1rem 0.375rem;
}

.ResultsPage__ProviderResult__body {
  margin-bottom: 0.75rem;
}

.ResultsPage__ProviderResult__provider-container {
  margin-left: 0.5rem;
}

h2.ResultsPage__ProviderResult__provider-name {
  font-size: 1rem;
  font-weight: 600;
  color: var(--gray-900);
}

p.ResultsPage__ProviderResult__priority {
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--purple-400);
}

.ResultsPage__ProviderResult__day {
  min-width: 124px;
  text-align: center;
}

.ResultsPage__ProviderResult__empty {
  color: var(--gray-400);
  font-size: 1.5rem;
}

h3.ResultsPage__ProviderResult__date {
  color: var(--gray-700);
  margin: 0.5rem 0;
  font-weight: 800;
  /* font-size: 0.875rem; */
  line-height: 1.5em;
  letter-spacing: 0.02em;
  font-variant: small-caps;
  text-align: center;
}

h3.ResultsPage__ProviderResult__date > span {
  font-weight: 400;
}

button.ResultsPage__ProviderResult__slot,
a.ResultsPage__ProviderResult__slot {
  display: block;
  width: 108px;
  text-align: center;
  /* background-color: var(--blue-200); */
  background-color: var(--mint-200);
  color: var(--dark-teal-900);
  /* border: 2px solid var(--blue-200); */
  border: 2px solid var(--mint-200);
  border-radius: 8px;
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 24px;
  margin: 0 18px 4px;
  cursor: pointer;
  transition: border-color 150ms;
}

button.ResultsPage__ProviderResult__slot:hover,
a.ResultsPage__ProviderResult__slot:hover {
  /* border: 2px solid var(--blue-500); */
  border: 2px solid var(--mint-600);
}

a.ResultsPage__ProviderResult__show-more {
  cursor: pointer;
}

.ResultsPage__ProviderResult__show-less {
  text-align: center;
  padding: 0.5rem;
}
