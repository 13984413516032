.Appointment {
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.07);
  margin: 0 1.5rem 0.5rem;
  cursor: pointer;
  transform: translateY(0);
  background-color: #fff;
  transition: transform 150ms, background-color 200ms, box-shadow 200ms;
}
.Appointment:hover {
  transform: translateY(-4px);
  background-color: var(--blue-100);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.12);
}

.Appointment {
  border: 1px solid var(--blue-500);
  border-radius: 8px;
  overflow: hidden;
}

.Appointment.Appointment--cancelled {
  border: 1px solid var(--red-500);
}

.Appointment.Appointment--completed {
  border: 1px solid var(--green-500);
}

.Appointment__icon-container {
  font-size: 2.25rem;
  padding: 1rem 1.5rem;
  color: white;
  background: var(--blue-500);
}

.Appointment__info-container {
  flex-direction: column;
  align-items: baseline;
}

.Appointment__status-label {
  color: var(--blue-500);
  font-weight: 700;
  font-size: 0.875rem;
  text-transform: uppercase;
}

.Appointment__warning-label {
  color: var(--yellow-700);
  font-weight: 700;
  font-size: 0.875rem;
}

.Appointment__warning-label__icon-container {
  color: var(--yellow-600);
  margin-right: 0.5rem;
}

/* Styles for Statuses: */
.Appointment--cancelled:hover {
  background-color: var(--red-100);
}

.Appointment--cancelled .Appointment__icon-container {
  background: var(--red-500);
}
.Appointment--cancelled .Appointment__status-label {
  color: var(--red-500);
}

.Appointment--completed:hover {
  background-color: var(--green-100);
}

.Appointment--completed .Appointment__icon-container {
  background: var(--green-500);
}
.Appointment--completed .Appointment__status-label {
  color: var(--green-500);
}
