.EmailConfirmationBanner {
  position: absolute;
  top: 2px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.875rem;
  padding: 0.5em 2em;
  max-width: 650px;
  background-color: var(--yellow-100);
  border: 1px solid var(--yellow-300);
  border-radius: 4px;
  color: var(--yellow-800);
}
