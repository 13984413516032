.CasePrograms__grid {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto auto;
  grid-gap: 1rem;
  margin: 0 1rem 0 0;
}

.CasePrograms__card {
  border: 1px solid rgba(0, 0, 0, 0.07);
}
