/* //Variables */
/* // #dedede: #9F9FA3; */
/* #dedede: #dedede; */
/* #4A90E2: #4A90E2; */
/* #DBF1FF: #DBF1FF; */

.StepProgressBar__container {
  max-width: 1000px;
  /* // font-size: 16px; */
  font-size: 12px;
  margin: 0 auto;
}

.StepProgressBar__progress_bar {
  margin: 0 auto;
  /* // padding: 2em 0 3em; */
  padding: 0 0 3em;
  list-style: none;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.StepProgressBar__step {
  text-align: center;
  position: relative;
  flex: 1 1 0%;
}
.StepProgressBar__step:before,
.StepProgressBar__step:after {
  content: "";
  height: 0.3em;
  background-color: #dedede;
  position: absolute;
  z-index: 1;
  width: 80%;
  left: -40%;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.25s ease-out;
  border-radius: 15px;
}

.StepProgressBar__step:first-child:before,
.StepProgressBar__step:first-child:after {
  display: none;
}

.StepProgressBar__step:after {
  /* background-color: #4a90e2; */
  /* Branding: */
  /* background-color: #c99700; */
  background-color: var(--teal-500);
  width: 0%;
}

.StepProgressBar__step.is_complete + .StepProgressBar__step.is_current:after,
.StepProgressBar__step.is_complete + .StepProgressBar__step.is_complete:after {
  width: 80%;
}

.StepProgressBar__step_stone {
  box-sizing: content-box;
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5em;
  font-size: 1.5em;
  background-color: #dedede;
  fill: #dedede;
  /* border: 1px solid #62a9fd; */
  /* Branding: */
  /* border: 1px solid var(--accent-green-500); */
  border-radius: 50%;
  padding: 0.25em;
  max-width: 100%;
  z-index: 10;
  position: relative;
  transition: all 0.25s ease-out;
}

.StepProgressBar__step.is_current .StepProgressBar__step_stone,
.StepProgressBar__step.is_complete .StepProgressBar__step_stone {
  /* background-color: #4a90e2; */
  /* Branding: */
  /* background-color: var(--mint-500); */
  background-color: var(--accent-green-500);
  /* border: 2px solid var(--mint-700); */
}

.StepProgressBar__step svg path {
  fill: transparent;
  /* background-color: #4a90e2; */
  /* Branding: */
  background-color: #c99700;
}

.StepProgressBar__step.is_complete svg path {
  fill: #ffffff;
  /* background-color: #4a90e2; */
  /* Branding: */
  background-color: #c99700;
}

.StepProgressBar__step_label {
  display: block;
  /* // color: #dedede; */
  /* // color: #ababab; */
  /* color: #80b1ea; */
  /* Branding: */
  color: #002855;
  position: absolute;
  padding-top: 0.5em;
  width: 100%;
  transition: all 0.25s ease-out;
}
.StepProgressBar__step_label .is_current > .StepProgressBar__step_label,
.StepProgressBar__step_label .is_complete > .StepProgressBar__step_label {
  color: #4a90e2;
}
