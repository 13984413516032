.AnalyticsPage__sidebar {
  max-width: 320px;
  margin: 1.5rem;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 320px;
}

.AnalyticsPage__sidebar-section-container {
  text-align: left;
  margin: 1.5rem 0 2.5rem;
}

.AnalyticsPage__sidebar-header {
  margin: 1.25rem 0;
  font-size: 1rem;
  font-weight: 600;
  color: var(--gray-600);
}

ul.AnalyticsPage__sidebar__reports {
  background-color: #ffffff;
  border: 1px solid var(--gray-300);
  border-radius: 0.5rem;
  padding: 0.5rem;
}

ul.AnalyticsPage__sidebar__reports > a {
  display: block;
  margin-bottom: 2px;
}
ul.AnalyticsPage__sidebar__reports > a:last-child {
  margin-bottom: 0;
}

ul.AnalyticsPage__sidebar__reports li {
  font-size: 14px;
  color: var(--gray-800);
  line-height: 33px;
  margin: 0 auto;
  text-align: left;
  border-radius: 4px;
  background-color: transparent;
  transition: background-color 300ms;
}

ul.AnalyticsPage__sidebar__reports li:hover {
  background-color: #d2e8fa;
}

ul.AnalyticsPage__sidebar__reports a.is-active li {
  background-color: #d2e8fa;
  color: var(--blue-500);
}

ul.AnalyticsPage__sidebar__reports li > span {
  display: inline-block;
  margin: 0 12px 0 16px;
  width: 16px;
  text-align: center;
}
