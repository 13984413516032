.SelectSkillMessage {
  margin: 2rem auto;
  text-align: center;
}

.SelectSkillMessage__icon-container {
  font-size: 2.5rem;
  color: var(--gray-500);
}

.SelectSkillMessage__message-container {
  font-size: 1.5rem;
  max-width: 400px;
  margin: 1.5rem auto;
}
