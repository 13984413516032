.ProviderResultsPage__FilterPanel {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.ProviderResultsPage__FilterPanel__field {
  margin: 0.75rem;
  text-align: left;
}
