.WeekNav__date-range-label {
  color: #6d7988;
  margin: 0.5rem 0;
  font-weight: 800;
  line-height: 1.5em;
  letter-spacing: 0.02em;
  font-feature-settings: "c2sc";
  font-variant: small-caps;
  text-align: center;
}

.WeekNav__date-range-label > span {
  font-weight: 400;
  font-feature-settings: normal;
  font-variant: normal;
  font-size: 0.8em;
  margin-left: 0.5rem;
}

button.WeekNav__nav-link--disabled {
  cursor: not-allowed;
  color: var(--gray-500);
}
