.Tooltip {
  position: relative;
}

.Tooltip > .Tooltip__tip-container {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -85%);
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  display: table;
  font-size: 0.875rem;
  font-family: sans-serif;
  text-align: center;
  border-radius: 0.375rem;
  padding: 0.25rem 0.75rem;
  transition: transform 150ms, opacity 150ms, visibility 150ms;
}

.Tooltip__tip-container {
  border: 1px solid var(--purple-300);
  background-color: var(--purple-100);
  color: var(--purple-700);
}

.Tooltip__tip-container.Tooltip__tip-container--gray {
  border: 1px solid var(--gray-300);
  background-color: var(--gray-100);
  color: var(--gray-700);
}

.Tooltip__tip-container.Tooltip__tip-container--blue {
  border: 1px solid var(--blue-300);
  background-color: var(--blue-100);
  color: var(--blue-700);
}

.Tooltip__tip-container.Tooltip__tip-container--teal {
  border: 1px solid var(--teal-300);
  background-color: var(--teal-100);
  color: var(--teal-700);
}

.Tooltip__tip-container.Tooltip__tip-container--green {
  border: 1px solid var(--green-300);
  background-color: var(--green-100);
  color: var(--green-700);
}

.Tooltip__tip-container.Tooltip__tip-container--yellow {
  border: 1px solid var(--yellow-300);
  background-color: var(--yellow-100);
  color: var(--yellow-700);
}

.Tooltip__tip-container.Tooltip__tip-container--red {
  border: 1px solid var(--red-300);
  background-color: var(--red-100);
  color: var(--red-700);
}

.Tooltip__tip-container.Tooltip__tip-container--purple {
  border: 1px solid var(--purple-300);
  background-color: var(--purple-100);
  color: var(--purple-700);
}

.Tooltip:hover > .Tooltip__tip-container {
  transform: translate(-50%, -135%);
  opacity: 1;
  visibility: visible;
}

/*
This works by stacking 2 trianges, slightly offset from one another to
appear as a single triangle with a border on the sides that make a right angle.
The triangles are made by having a div with 0x0 size, but with borders, which
when intersecting form 45deg angles. A single colored border next to 2
transparent borders then appears as a triangle.
*/

.Tooltip__tip-inner {
  position: relative;
  display: block;
}
.Tooltip__tip__back-triangle {
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translate(-50%, 100%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid var(--purple-300);
}
.Tooltip__tip__front-triangle {
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translate(-50%, 100%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  border-top: 10px solid var(--purple-100);
}

.Tooltip__tip-container--gray .Tooltip__tip__back-triangle {
  border-top: 10px solid var(--gray-300);
}
.Tooltip__tip-container--gray .Tooltip__tip__front-triangle {
  border-top: 10px solid var(--gray-100);
}

.Tooltip__tip-container--blue .Tooltip__tip__back-triangle {
  border-top: 10px solid var(--blue-300);
}
.Tooltip__tip-container--blue .Tooltip__tip__front-triangle {
  border-top: 10px solid var(--blue-100);
}

.Tooltip__tip-container--teal .Tooltip__tip__back-triangle {
  border-top: 10px solid var(--teal-300);
}
.Tooltip__tip-container--teal .Tooltip__tip__front-triangle {
  border-top: 10px solid var(--teal-100);
}

.Tooltip__tip-container--green .Tooltip__tip__back-triangle {
  border-top: 10px solid var(--green-300);
}
.Tooltip__tip-container--green .Tooltip__tip__front-triangle {
  border-top: 10px solid var(--green-100);
}

.Tooltip__tip-container--yellow .Tooltip__tip__back-triangle {
  border-top: 10px solid var(--yellow-300);
}
.Tooltip__tip-container--yellow .Tooltip__tip__front-triangle {
  border-top: 10px solid var(--yellow-100);
}

.Tooltip__tip-container--red .Tooltip__tip__back-triangle {
  border-top: 10px solid var(--red-300);
}
.Tooltip__tip-container--red .Tooltip__tip__front-triangle {
  border-top: 10px solid var(--red-100);
}

.Tooltip__tip-container--purple .Tooltip__tip__back-triangle {
  border-top: 10px solid var(--purple-300);
}
.Tooltip__tip-container--purple .Tooltip__tip__front-triangle {
  border-top: 10px solid var(--purple-100);
}
