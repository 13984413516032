/*
.Tab {
  display: inline-block;
  margin: 0 1.25rem;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.875rem 0;
  border-bottom: 2px solid transparent;
  color: var(--gray-600);
  cursor: pointer;
  user-select: none;
}

.Tab:hover {
  border-bottom: 2px solid var(--gray-400);
  color: var(--gray-700);
}

.Tab--active {
  border-bottom: 2px solid var(--blue-500) !important;
  color: var(--blue-600) !important;
}
*/

.Status {
  user-select: none;
}

.Status > span {
  display: inline-block;
  padding: 0 14px;
  margin: 0.25rem 0;
  height: 1.5rem;
  line-height: 1.5rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  border-radius: 12px;
  color: var(--gray-700);
  background-color: var(--gray-200);
  font-weight: 600;
}

.Status__scheduled > span {
  color: var(--blue-700);
  background-color: var(--blue-200);
}

.Status__completed > span {
  color: var(--green-700);
  background-color: var(--green-200);
}

.Status__cancelled > span {
  color: var(--red-700);
  background-color: var(--red-200);
}

.Status__reschedule {
  color: var(--yellow-700);
  font-size: 0.75rem;
  font-weight: 600;
}
.Status__reschedule > span.icon-container {
  color: var(--yellow-600);
}

.WorklistPage__no-results {
  margin: 2rem;
}

.WorklistPage__no-results__icon-container {
  background-color: var(--gray-200);
  width: 5rem;
  height: 5rem;
  font-size: 2rem;
  line-height: 5rem;
  text-align: center;
  border-radius: 0.75rem;
  color: var(--gray-600);
  margin: 2rem auto;
}

.WorklistPage__no-results > h1 {
  font-size: 1.5rem;
  margin-bottom: 3rem;
  color: var(--gray-800);
}

.WorklistPage__table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  display: flex;
  flex-direction: column;
}

.WorklistPage__thead {
  display: flex;
  flex-direction: column;
  /* position: sticky; */
  /* top: 0; */
}

.WorklistPage__th {
  display: table-cell;
  border: 1px solid #dbdbdb;
  border-width: 0 0 2px;
  padding: 0.5em 0.75em;
  color: #363636;
  text-align: left;
  vertical-align: top;
  font-weight: bold;
}

/*
.WorklistPage__tbody {
  // display: table-row-group;
  // vertical-align: middle;
}
*/

.WorklistPage__tr {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1rem;
}

.WorklistPage__tr--is-occurring {
  border-left: 6px solid var(--blue-300) !important;
}

.WorklistPage__tr--is-finished {
  border-left: 6px solid var(--mint-500) !important;
}

.WorklistPage__thead > .WorklistPage__tr {
  background-color: #f6f8f9;
  color: var(--gray-700);
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.875rem;
  letter-spacing: 0.06em;
  border: 1px solid var(--gray-300);
  border-width: 0 0 2px;
  border-radius: 8px 8px 0px 0px;
}

.WorklistPage__tbody > .WorklistPage__tr {
  border-color: inherit;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-width: 0 0 1px;
  transition: background-color 100ms ease-in-out;
}

.WorklistPage__tbody > .WorklistPage__tr:hover {
  background-color: var(--blue-100);
}

.WorklistPage__td {
  flex-basis: 12.5%;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.5em 0.75em;
  vertical-align: top;
  text-align: left;
  color: var(--gray-800);
}

.WorklistPage__td--case_number {
  flex-basis: 100px;
  max-width: 100px;
  flex-grow: 0;
  flex-shrink: 0;
}

.WorklistPage__td--state {
  flex-basis: 68px;
  max-width: 68px;
  flex-grow: 0;
  flex-shrink: 0;
}

.WorklistPage__td--status {
  flex-basis: 140px;
  max-width: 140px;
  flex-grow: 0;
  flex-shrink: 0;
}

.WorklistPage__td--modality {
  flex-basis: 140px;
  max-width: 140px;
  flex-grow: 0;
  flex-shrink: 0;
}

.WorklistPage__td--modality > p {
  line-height: 1.2;
}

.WorklistPage__td--health_plan {
  min-width: 0;
  white-space: nowrap;
}
.WorklistPage__td--health_plan > p {
  overflow: hidden;
  text-overflow: ellipsis;
}

.WorklistPage__td--org {
  max-width: 162px;
  min-width: 162px;
  white-space: nowrap;
}
.WorklistPage__td--org > p {
  overflow: hidden;
  text-overflow: ellipsis;
}

.WorklistPage__td--date {
  max-width: 136px;
  min-width: 136px;
}

.WorklistPage__table tr {
  height: 56px;
  cursor: pointer;
  background-color: white;
  transition: background-color 150ms;
}

.WorklistPage__table tr:hover {
  background-color: var(--blue-100);
}

.WorklistPage__table td {
  padding: 0;
  vertical-align: middle;
}

.WorklistPage__emph {
  font-size: 1rem;
  font-weight: 600;
}

.WorklistPage__sub {
  font-size: 0.875rem;
  font-weight: normal;
  color: var(--gray-700);
}

.WorklistPage__load-more {
  margin: 0.75rem;
}
