/*
.ToastDisplay__container {
  position: fixed;
  z-index: 1000;
  width: 0 auto;
  top: 30px;
  margin: 0 auto;
  right: 30px;
  display: flex;
  flex-direction: column-reverse;
  pointer-events: none;
  align-items: center;
}
*/

.ToastDisplay__container {
  width: 250px;
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column-reverse;
  z-index: 4;
}

.Toast__msg {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  width: 250px;
}

.Toast__content {
  position: relative;
  color: white;
  background: #171717;
  margin-top: 10px;
  padding: 15px;
  font-size: 0.8em;
  line-height: 1.5em;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 15px;
  border-radius: 5px;
  overflow: hidden;
  text-align: left;
}


/**
 * Success
 */
 .Toast__content.Toast__content-success {
  background: var(--green-100);
  color: var(--green-800);
  border: 1px solid var(--green-300);
}
.Toast__content.Toast__content-success > button {
  background: var(--green-200);
  border: 1px solid var(--green-400);
}
.Toast__content.Toast__content-success > button:hover {
  background: var(--green-400) !important;
}

.Toast__content-success .Toast__life {
  background: var(--green-700);
}

/**
 * Info
 */
.Toast__content.Toast__content-info {
  background: var(--blue-100);
  color: var(--blue-800);
  border: 1px solid var(--blue-300);
}
.Toast__content.Toast__content-info > button {
  background: var(--blue-200);
  border: 1px solid var(--blue-400);
}
.Toast__content.Toast__content-info > button:hover {
  background: var(--blue-400) !important;
}

.Toast__content-info .Toast__life {
  background: var(--blue-700);
}

/**
 * Warning
 */
.Toast__content.Toast__content-warning {
  background: var(--yellow-100);
  color: var(--yellow-800);
  border: 1px solid var(--yellow-300);
}
.Toast__content.Toast__content-warning > button {
  background: var(--yellow-200);
  border: 1px solid var(--yellow-400);
}
.Toast__content.Toast__content-warning > button:hover {
  background: var(--yellow-400) !important;
}

.Toast__content-warning .Toast__life {
  background: var(--yellow-700);
}

/**
 * Danger
 */
.Toast__content.Toast__content-danger {
  background: var(--red-100);
  color: var(--red-800);
  border: 1px solid var(--red-300);
}
.Toast__content.Toast__content-danger > button {
  background: var(--red-200);
  border: 1px solid var(--red-400);
}
.Toast__content.Toast__content-danger > button:hover {
  background: var(--red-400) !important;
}

.Toast__content-danger .Toast__life {
  background: var(--red-700);
}

.Toast__content > p {
  margin-top: 0;
  margin-bottom: 0;
}

.Toast__content > span {
  font-size: 2em;
}

.Toast__content > button {
  position: relative;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 50%;
  background: #575757;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.Toast__content > button:hover {
  background: rgb(255, 207, 0) !important;
}

.Toast__life {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: auto;
  background: rgb(255, 207, 0);
  height: 4px;
}
