.ConfirmationFailure h1 {
  color: var(--yellow-800) !important;
}

.ConfirmationFailure__check-container {
  width: 60px;
  height: 60px;
  font-size: 36px;
  line-height: 60px;
  margin: 0 auto 2rem;
  border: 1px solid var(--yellow-500);
  color: var(--yellow-500);
  background-color: var(--yellow-100);
  border-radius: 50%;
}
