.GradientHeader-primary {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  text-align: left;
  /* primary-500 to primary-400 gradient: */
  background: linear-gradient(
    106.42deg,
    var(--teal-500) 21.21%,
    var(--accent-green-500) 95.2%
  );
  padding: 48px 56px;
}
.GradientHeader-primary h1 {
  font-size: 28px;
  font-weight: 600;
  color: white; /* primary-100 */
  margin-bottom: 16px;
  line-height: 1;
}
.GradientHeader-primary h6 {
  font-size: 14px;
  font-weight: 600;
  color: var(--teal-50); /* primary-300 */
  line-height: 1;
}
