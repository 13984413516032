.LoginAttempts table.table td {
  border: none;
}

.LoginAttempts tr {
  font-size: 0.875rem;
  background-color: transparent;
  transition: background-color 300ms;
}

.LoginAttempts tr.success {
  background-color: var(--green-100);
  color: var(--green-800);
}

.LoginAttempts tr.failure {
  background-color: var(--red-100);
  color: var(--red-800);
}

.LoginAttempts tr.is-active {
  background-color: var(--blue-100);
}

.LoginAttempts tr:hover {
  cursor: pointer;
  background-color: var(--blue-100);
}
