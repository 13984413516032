.WizardContainer {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 10fr 1fr;
  grid-template-columns: 1fr 10fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.WizardStep {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / span 1;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1 / span 1;
  margin-bottom: 2rem;
  opacity: 1;
  transition: background-color 2s, transform 1s, opacity 1s;
}

.WizardStep.past {
  opacity: 0;
  transform: translateX(-100%);
  pointer-events: none;
}

.WizardStep.future {
  opacity: 0;
  transform: translateX(100%);
  pointer-events: none;
}

.WizardStep.full_width {
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  grid-column: 1 / span 3;
}
