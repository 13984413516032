.UserDropdown {
  margin-right: 29px;
}
.UserDropdown a,
.UserDropdown span {
  text-decoration: none;
}

.UserDropdown__trigger {
  display: flex;
  align-items: center;
  border: 1px solid #cfcfcf;
  padding: 4px 10px;
  border-radius: 4px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.09);
  cursor: pointer;
  user-select: none;
  background-color: white;
}
.UserDropdown__trigger:hover {
  background-color: #f6f6f6;
}
.UserDropdown__trigger img {
  width: 25px;
  /* border: 1px solid #349aef; */
  border-radius: 50%;
}
.UserDropdown__trigger span {
  margin-left: 10px;
  font-weight: 500;
  color: #999;
}
