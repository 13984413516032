.BigAppointmentDetails {
  font-size: 1.25rem;
  padding: 1rem;
  border: 1px solid var(--blue-200);
  border-radius: 0.5rem;
  margin-right: 1rem;
}

.BigAppointmentDetails__copy-container {
  margin-bottom: 0.75rem;
  text-align: center;
}

.BigAppointmentDetails__icon-container {
  margin-right: 0.75em;
  color: var(--gray-600);
}

.BigAppointmentDetails__warning .BigAppointmentDetails__icon-container {
  color: var(--yellow-600) !important;
}

.BigAppointmentDetails__warning__message {
  color: var(--yellow-700);
  font-weight: 600;
}

.BigAppointmentDetails--scheduled
  .BigAppointmentDetails__status-icon-container {
  color: var(--blue-400);
}

.BigAppointmentDetails--completed
  .BigAppointmentDetails__status-icon-container {
  color: var(--green-400);
}

.BigAppointmentDetails--cancelled
  .BigAppointmentDetails__status-icon-container {
  color: var(--red-400);
}

.BigAppointmentDetails__status {
  font-weight: 600;
  text-transform: uppercase;
}

.BigAppointmentDetails--scheduled .BigAppointmentDetails__status {
  color: var(--blue-600);
}

.BigAppointmentDetails--completed .BigAppointmentDetails__status {
  color: var(--green-600);
}

.BigAppointmentDetails--cancelled .BigAppointmentDetails__status {
  color: var(--red-600);
}
