.Avatar {
  /* This image is 687 wide by 1024 tall, similar to your aspect ratio */
  /* background-image: url("http://i.stack.imgur.com/Dj7eP.jpg"); */

  /* make a square container */
  /* width: 150px; */
  /* height: 150px; */

  /* fill the container, preserving aspect ratio, and cropping to fit */
  background-size: cover;

  /* center the image vertically and horizontally */
  background-position: top center;

  /* round the edges to a circle with border radius 1/2 container size */
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px hsla(0, 0, 0, 0.1);
}

.Avatar__letters {
  position: relative;
  float: left;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  user-select: none;
}
