.ModalHeader {
  min-width: 500px;
}

.Modal__section {
  padding: 1rem 2rem;
}
.Modal__section:last-child {
  padding: 1rem 2rem 2rem;
}
