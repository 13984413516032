.DateRangeDropdown__container {
  display: inline-block;
  position: relative;
  /* margin: 1rem auto; */
}

.DateRangeDropdown__trigger {
  padding: 0.5rem 0;
  background: white;
  border: 1px solid #cccc;
  border-radius: 6px;
  cursor: pointer;
}

.DateRangeDropdown__trigger__date {
  font-size: 1rem;
  font-weight: 600;
  color: var(--blue-700);
  display: inline-block;
  width: 110px;
  text-align: center;
  white-space: nowrap;
  margin: 0 0.75rem;
  line-height: 1rem;
}

.DateRangeDropdown__trigger__date-divider {
  display: inline-block;
  line-height: 1rem;
  color: var(--gray-500);
}

.DateRangeDropdown__trigger__chevron {
  display: inline-block;
  line-height: 1rem;
  color: var(--gray-500);
  margin: 0 0.5rem 0 0.875rem;
}

.DateRangeDropdown__content {
  position: absolute;
  background: white;
  transition: opacity 250ms, transform 250ms, visibility 250ms;
  left: 0;
  min-width: 250px;
  border: 1px solid #cfcfcf;
  border-radius: 4px;
  /* box-shadow: 0 2px 3px rgba(0,0,0,0.3); */
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.09);
  z-index: 3;
}

.DateRangeDropdown__content--open {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.DateRangeDropdown__content--closed {
  visibility: hidden;
  opacity: 0;
  transform: translateY(-20px);
}

.DateRangeDropdown__content__footer {
  padding: 0.75rem;
  text-align: right;
}

.DateRangeDropdown__content__footer > button {
  margin-right: 0.5rem;
}
.DateRangeDropdown__content__footer > button:last-child {
  margin-right: 0;
}
