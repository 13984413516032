.ConfirmationSuccess h1 {
  color: var(--green-800) !important;
}

.ConfirmationSuccess__check-container {
  width: 60px;
  height: 60px;
  font-size: 36px;
  line-height: 60px;
  margin: 0 auto 2rem;
  border: 1px solid var(--green-500);
  color: var(--green-500);
  background-color: var(--green-100);
  border-radius: 50%;
}
