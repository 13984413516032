label.de-emph-label {
  font-size: 0.75rem;
  color: var(--gray-600);
  text-transform: uppercase;
  font-weight: 600;
}
p.emph {
  font-size: 1.5rem;
}

.AppointmentRequestShowPage__ref-number {
  color: var(--gray-600);
}

.QuickStatus {
  text-align: right;
}

.QuickStatus h3 {
  font-size: 1.25rem;
  font-weight: 600;
  text-transform: uppercase;
}

.QuickStatus--scheduled h3 {
  color: var(--blue-600);
}

.QuickStatus--completed h3 {
  color: var(--green-600);
}

.QuickStatus--cancelled h3 {
  color: var(--red-600);
}

.QuickStatus h4 {
  font-size: 1rem;
  font-weight: 600;
  color: var(--yellow-700);
}

.QuickStatus h4 > .QuickStatus__icon-container {
  margin-right: 0.5em;
  color: var(--yellow-600);
}
