.ToggleButtons {
  display: inline-flex;
  margin: 0 -0.25rem;
}

.ToggleButton {
  background-color: transparent;
  color: var(--blue-400);
  border-radius: 0.25rem;
  margin: 0.25rem;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  border: none;
  font-weight: 600;
  font-size: 1rem;
}
.ToggleButton:hover {
  background-color: var(--blue-200);
  color: var(--blue-500);
}

.ToggleButton.ToggleButton__active {
  background-color: var(--blue-500);
  color: white;
}
.ToggleButton.ToggleButton__active:hover {
  background-color: var(--blue-500);
  color: white;
}
